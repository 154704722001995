import { Box, Flex } from 'grid-styled'
import * as React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'
import Map from '../components/Map/Map'

import CTA from '../components/CTA'
import Header from '../components/Header'
import FAQs from '../components/FAQs'
import Contact from '../components/Contact'
import Hero from '../components/Hero'
import Button from '../components/Button'
import styled from 'styled-components'

const Content = styled.div`
  max-width: 750px;
  width: 90%;
  margin: 80px auto 50px;

  h2 {
    font-weight: 400;
    margin-bottom: 1em;
  }

  span {
    color: ${(props) => props.theme.colors.primary};
    font-size: 20px;
    display: inline-block;
    margin-bottom: 1em;
  }
`

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>Contact Brisbane & Gold Coast Psychologist - Upright Performance</title>
      <meta
        name="description"
        content="Rob Morgan is a clinical psychologist and mental health consultant in Brisbane & Gold Coast. We provide behaviour support, supervision, mental health services, as well as training and consulting."
      />
    </Helmet>
    <Box mt={5} pt={4} style={{ textAlign: 'center' }}>
      <Contact
        style={{ paddingTop: '3rem', textAlign: 'left' }}
        noBorder={true}
      />
    </Box>
    <Map style={{ marginBottom: 0 }} />
  </Layout>
)
